import { InferGetStaticPropsType } from "next";
import axios from 'axios';
import Homepage from '@/components/Homepage';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { NextSeo } from "next-seo";
import Assistant from "@/components/Common/Assistant";
import Head from "next/head";


const Home = ({
  homepage,
}: InferGetStaticPropsType<typeof getStaticProps>) => {

  return (
    <>
      <Head>
        <meta name="google-site-verification" content="aCfV93j2VBW65ZRV5NtH7DVaN8vhTfv-eevd4c0jb9w" />
      </Head>
      <NextSeo
        title=''
        defaultTitle='MOOIMOM: Beli & Konsultasi Online Perlengkapan Bayi & Ibu'
        canonical={process.env.NEXT_PUBLIC_HOSTNAME}
        openGraph={{
          images: [{
            url: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/homepage.jpg",
            width: 200,
            height: 200,
            alt: 'MOOIMOM logo image',
          }]
        }}
      />
      <Header top_text_list={homepage.data.top_text_list} />
      <Homepage
        main_banner={homepage.data.main_banner_list}
        our_picks={homepage.data.our_pick_list}
        people_say={homepage.data.reviews}
        banner_list={homepage.data.banner_list}
        mamapedia={homepage.data.mamapedia}
        flash_sale={homepage.data.flash_sale}
      />
      <Assistant />
      <Footer />
    </>
  )
}

export default Home

export const getStaticProps = async () => {
  const response = await axios.get(
    process.env.NEXT_PUBLIC_API_URL! + process.env.NEXT_PUBLIC_API_HOMEPAGE!
  );

  return {
    props: { homepage: response.data },
    revalidate: 60,
  }
}
