import Image from 'next/image';
import Link from 'next/link';
import Cookies from 'js-cookie';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import MinProduct from '../Product/MinProduct';
import { OurPicksItemProps } from './constants';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { motionVariants } from '../Common/GlobalVars';

import "swiper/css";
import 'swiper/css/effect-fade';
import "swiper/css/pagination";
import "swiper/css/navigation";


const OurPick = (props: OurPicksItemProps) => {
  const { tittle, link, banner_list, product_list, attrId, customName, expandColor, showBorder, image, customMarginTop, customMarginBottom } = props;
  const viewport = Cookies.get('viewport');

  // const render_expand_product = () => {
  //   let count = 0;

  //   return product_list.map((product) => {
  //     return product.product_variant_list.map((variant) => {
  //       count++;

  //       return (((viewport === "desktop" && count <= 5) || (viewport === "mobile" && count <= 4)) &&
  //         <MinProduct
  //           key={count}
  //           attribute_value_list={product.attribute_value_list}
  //           brand={product.brand}
  //           cash_back={product.cash_back}
  //           id={product.id}
  //           cart_image={product.cart_image}
  //           image={product.image}
  //           name={product.name}
  //           product_discount_percent={product.product_discount_percent}
  //           product_label_bottom_left_category_page={product.product_label_bottom_left_category_page}
  //           product_label_bottom_right_category_page={product.product_label_bottom_right_category_page}
  //           product_label_left_product_page={product.product_label_left_product_page}
  //           product_label_right_product_page={product.product_label_right_product_page}
  //           msrp={product.msrp}
  //           qty={product.qty}
  //           sales_price={product.sales_price}
  //           sku={product.sku}
  //           slug={product.slug}
  //           special_price={product.special_price}
  //           type={product.type}
  //           url={product.url}
  //           product_variant_list={product.product_variant_list}
  //           attrId={variant.attribute_value_list[0]}
  //           customName={customName}
  //         />
  //       )
  //     })
  //   })
  // }

  return (
    <>
      {(tittle !== null && tittle !== undefined && tittle.length > 0 && link != null && link.length > 0) ? (
        <motion.div
          className={`flex items-center justify-between mb-3 mt-8 md:mt-12 ${viewport === "desktop" ? "" : "container"}`}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          variants={motionVariants}
        >
          <h2 className="text-base font-bold text-black md:text-2xl font-poppins">{tittle}</h2>
          <Link href={link}>
            <div className="text-xs font-normal text-black underline font-poppins">
              Lihat Semua
            </div>
          </Link>
        </motion.div>
      ) : (tittle !== null && tittle !== undefined && tittle.length > 0 && (link == null || link.length == 0)) && (
        <motion.div
          className={`${viewport === "desktop" ? "" : "container"} mt-5`}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
          variants={motionVariants}
        >
          <h2 className="mt-8 mb-3 text-base font-bold text-black md:text-2xl font-poppins md:mt-12">{tittle}</h2>
        </motion.div>
      )}
      <div className={`${((tittle !== null && tittle !== undefined && tittle.length > 0) || (link !== null && link.length > 0)) ? "" : customMarginTop ? customMarginTop : "mt-16"} ${customMarginBottom ? customMarginBottom : (!showBorder) ? "mb-10" : "pt-12 md:pb-12 border-t md:border-b border-sku"}`}>
        {!expandColor && product_list !== null && product_list.length > 0 && (
          <Swiper
            spaceBetween={16}
            centeredSlides={false}
            loop={false}
            navigation={viewport === "desktop" ? true : false}
            slidesPerView={viewport === "desktop" ? 5 : 2.2}
            modules={[Navigation]}
            className="!py-3 !pl-6 md:!pl-0"
          >
            {product_list.sort((a, b) => {
              if (a.qty === 0 && b.qty === 0) {
                return 0;
              } else if (a.qty === 0) {
                return 1;
              } else if (b.qty === 0) {
                return -1;
              } else {
                return 0;
              }
            }).map((product, index) => (
              <SwiperSlide key={index}>
                <MinProduct
                  key={index}
                  attribute_value_list={product.attribute_value_list}
                  brand={product.brand}
                  cash_back={product.cash_back}
                  id={product.id}
                  cart_image={product.cart_image}
                  image={product.image}
                  name={product.name}
                  product_discount_percent={product.product_discount_percent}
                  product_label_bottom_left_category_page={product.product_label_bottom_left_category_page}
                  product_label_bottom_right_category_page={product.product_label_bottom_right_category_page}
                  product_label_left_product_page={product.product_label_left_product_page}
                  product_label_right_product_page={product.product_label_right_product_page}
                  msrp={product.msrp}
                  qty={product.qty}
                  sales_price={product.sales_price}
                  sku={product.sku}
                  slug={product.slug}
                  special_price={product.special_price}
                  type={product.type}
                  url={product.url}
                  product_variant_list={product.product_variant_list}
                  attrId={attrId}
                  customName={customName}
                  setSelectProduct={props.setSelectProduct}
                  virtual_product={product.virtual_product}
                  need_shipping={product.need_shipping}
                  custom_variant_list={product.custom_variant_list}
                  selectedCustomVariant={props.selectedCustomVariant}
                  setSelectedCustomVariant={props.setSelectedCustomVariant}
                  fromName={props.fromName}
                  setFromName={props.setFromName}
                  toName={props.toName}
                  setToName={props.setToName}
                  toEmail={props.toEmail}
                  setToEmail={props.setToEmail}
                  toMobile={props.toMobile}
                  setToMobile={props.setToMobile}
                  toMessage={props.toMessage}
                  setToMessage={props.setToMessage}
                  pregnancy={props.pregnancy}
                  setPregnancy={props.setPregnancy}
                  duDate={props.duDate}
                  setDuDate={props.setDuDate}
                  childName={props.childName}
                  setChildName={props.setChildName}
                  childAge={props.childAge}
                  setChildAge={props.setChildAge}
                  childGender={props.childGender}
                  setChildGender={props.setChildGender}
                  pwp_name={product.pwp_name}
                  pwp={product.pwp}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {/* {expandColor && product_list.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-5 gap-x-4 gap-y-8">
            {render_expand_product()}
        </div>
        )} */}
      </div>
    </>
  )
}

export default OurPick
