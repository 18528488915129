import MainBanner from './MainBanner';
import OurPickList from './OurPickList';
import { HomepageItemProps } from './constants';
import Stage from './Stage';
import ReactPlayer from 'react-player/youtube';
import CarouselBanner from '../Utils/CarouselBanner';
import Reviews from './Reviews';
import MamapediaList from './MamapediaList';
import Benefit from './Benefit';
import FeatureBrands from './FeatureBrands';
import { useContext, useEffect, useRef, useState } from 'react';
import AddToCart from '../Product/AddToCart';
import { CustomVariantItemProps, MinProductItemProps, ProductVariantItemProps } from '../Product/constants';
import { GlobalContext, GlobalState } from '@/context/useGlobalContext';
import { useUserStore } from '@/stores/useUserStore';
import { useRouter } from 'next/router';
import ShopByCategory from './ShopByCategory';
import Cookies from 'js-cookie';
import FlashSale from './FlashSale';


const Homepage = (props: HomepageItemProps) => {
  const { main_banner, our_picks, banner_list, people_say, mamapedia } = props;
  const router = useRouter();
  const { showCart, setShowCart } = useContext(GlobalContext) as GlobalState;
  const { userInfo, getUser } = useUserStore();
  const [userData, setUserData] = useState(getUser());
  const [selectProduct, setSelectProduct] = useState<MinProductItemProps | null>(null);
  const [variant, setVariant] = useState<ProductVariantItemProps | null>(null);
  const [open, setOpen] = useState(false);
  const [videoHeight, setVideoHeight] = useState(0);
  const videoRef = useRef<HTMLDivElement>(null);
  const [selectedCustomVariant, setSelectedCustomVariant] = useState<CustomVariantItemProps | null>(null);
  const [fromName, setFromName] = useState<string>('');
  const [toName, setToName] = useState<string>('');
  const [toEmail, setToEmail] = useState<string>('');
  const [toMobile, setToMobile] = useState<string>('');
  const [toMessage, setToMessage] = useState<string>('');
  const [pregnancy, setPregnancy] = useState<string>('');
  const [duDate, setDuDate] = useState<Date | null>(null);
  const [childName, setChildName] = useState<string>('');
  const [childAge, setChildAge] = useState<number | null>(null);
  const [childGender, setChildGender] = useState<string>('');
  const [affiliate, setAffiliate] = useState<string | undefined>(undefined);
  const [showFlashSale, setShowFlashSale] = useState(false);

  useEffect(() => {
    if (videoRef.current !== null) {
      setVideoHeight(videoRef.current.offsetWidth * 0.5625);
    }

    if (window !== undefined) {
      setAffiliate(Cookies.get(window.btoa("affiliate")));
    }
  }, [])

  useEffect(() => {
    if (props.flash_sale && props.flash_sale.length > 0) {
      const checkFlashSale = () => {
        const currentTime = Date.now();
        const flashSale = props.flash_sale[0];

        if (flashSale.start_time <= currentTime && flashSale.end_time >= currentTime) {
          setShowFlashSale(true);
        } else {
          setShowFlashSale(false);
        }
      };

      checkFlashSale(); // Initial check
      const intervalId = setInterval(checkFlashSale, 30000); // Check every 30 seconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }
  }, [props.flash_sale]);

  useEffect(() => {
    if (selectProduct !== null && !open) {
      if (userData.membershiplevel === "none" || userData.membershiplevel === "" || userData.user_id === undefined) {
        router.push('/login');
      } else {
        setOpen(true);
      }
    }
  }, [selectProduct, open, userData.membershiplevel, userData.user_id, router])

  useEffect(() => {
    if (!open) {
      setSelectProduct(null);
    }
  }, [open])

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  return (
    <>
      <MainBanner main_banner={main_banner} />
      {showFlashSale && (
        <FlashSale
          flash_sale={props.flash_sale[0]}
          setSelectProduct={setSelectProduct}
          selectedCustomVariant={selectedCustomVariant}
          setSelectedCustomVariant={setSelectedCustomVariant}
          fromName={fromName}
          setFromName={setFromName}
          toName={toName}
          setToName={setToName}
          toEmail={toEmail}
          setToEmail={setToEmail}
          toMobile={toMobile}
          setToMobile={setToMobile}
          toMessage={toMessage}
          setToMessage={setToMessage}
          pregnancy={pregnancy}
          setPregnancy={setPregnancy}
          duDate={duDate}
          setDuDate={setDuDate}
          childName={childName}
          setChildName={setChildName}
          childAge={childAge}
          setChildAge={setChildAge}
          childGender={childGender}
          setChildGender={setChildGender}
          setShowFlashSale={setShowFlashSale}
        />
      )}
      <Stage option={process.env.NEXT_PUBLIC_STYLE_OPTION || "B"} />
      {banner_list.length > 0 && (
        <CarouselBanner
          title={banner_list[0].title}
          image_list={banner_list[0].image_list}
          carousel={banner_list[0].carousel}
          desktop_slides={4}
          mobile_slides={1.2}
          event_category="Homepage"
          router={router}
        />
      )}
      <OurPickList
        our_picks={our_picks}
        setSelectProduct={setSelectProduct}
        selectedCustomVariant={selectedCustomVariant}
        setSelectedCustomVariant={setSelectedCustomVariant}
        fromName={fromName}
        setFromName={setFromName}
        toName={toName}
        setToName={setToName}
        toEmail={toEmail}
        setToEmail={setToEmail}
        toMobile={toMobile}
        setToMobile={setToMobile}
        toMessage={toMessage}
        setToMessage={setToMessage}
        pregnancy={pregnancy}
        setPregnancy={setPregnancy}
        duDate={duDate}
        setDuDate={setDuDate}
        childName={childName}
        setChildName={setChildName}
        childAge={childAge}
        setChildAge={setChildAge}
        childGender={childGender}
        setChildGender={setChildGender}
      />
      <ShopByCategory />
      <Benefit />
      {banner_list.length > 1 && (
        <CarouselBanner
          title={banner_list[1].title}
          image_list={banner_list[1].image_list}
          carousel={banner_list[1].carousel}
          desktop_slides={2.3}
          mobile_slides={1.2}
          event_category="Homepage"
          router={router}
        />
      )}
      <FeatureBrands />
      <Reviews reviews={people_say} option={process.env.NEXT_PUBLIC_STYLE_OPTION || "B"} />
      <div className="container flex justify-center mb-12 video-section">
        <div className="w-full md:w-[90%]" ref={videoRef}>
          <ReactPlayer
            url="https://www.youtube.com/watch?v=zi38XZfzSgg"
            width="100%"
            height={videoHeight}
            playing={false}
            loop={true}
            controls={true}
            muted={true}
          />
        </div>
      </div>
      <MamapediaList articles={mamapedia} />
      {selectProduct !== null && (
        <AddToCart
          id={selectProduct.id}
          name={selectProduct.name}
          msrp={selectProduct.msrp}
          sales_price={selectProduct.sales_price}
          product_code={selectProduct.sku}
          qty={selectProduct.qty}
          image={selectProduct.image}
          attribute_value_list={selectProduct.attribute_value_list}
          product_variant_list={selectProduct.product_variant_list}
          special_price={selectProduct.special_price}
          brand={selectProduct.brand}
          slug={selectProduct.slug}
          style="popup"
          variant={variant}
          setVariant={setVariant}
          setShowCart={setShowCart}
          action="add"
          open={open}
          setOpen={setOpen}
          virtual_product={selectProduct.virtual_product}
          need_shipping={selectProduct.need_shipping}
          custom_variant_list={selectProduct.custom_variant_list}
          selectedCustomVariant={selectedCustomVariant}
          setSelectedCustomVariant={setSelectedCustomVariant}
          fromName={fromName}
          setFromName={setFromName}
          toName={toName}
          setToName={setToName}
          toEmail={toEmail}
          setToEmail={setToEmail}
          toMobile={toMobile}
          setToMobile={setToMobile}
          toMessage={toMessage}
          setToMessage={setToMessage}
          pregnancy={pregnancy}
          setPregnancy={setPregnancy}
          duDate={duDate}
          setDuDate={setDuDate}
          childName={childName}
          setChildName={setChildName}
          childAge={childAge}
          setChildAge={setChildAge}
          childGender={childGender}
          setChildGender={setChildGender}
          pwp_name={selectProduct.pwp_name}
          pwp={selectProduct.pwp}
          shared_sales_price={null}
          type={affiliate !== null && affiliate !== undefined ? "affiliate" : "ec"}
        />
      )}
    </>
  )
}

export default Homepage
