import Image from 'next/image';
import { motion } from "framer-motion"
import { BannersItemProps } from "../Homepage/constants"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import Cookies from 'js-cookie';
import { motionVariants } from '../Common/GlobalVars';
import Link from 'next/link';
import { event } from 'nextjs-google-analytics';
import { NextRouter } from 'next/router';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


interface CarouselBannerProps extends BannersItemProps {
  desktop_slides: number;
  mobile_slides: number;
  event_category: string;
  router: NextRouter;
}

const CarouselBanner = (props: CarouselBannerProps) => {
  const {title, image_list, desktop_slides, mobile_slides} = props;
  const viewport = Cookies.get('viewport');

  const handleBannerClick = (link: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    event("banner_click", {
      event_category: props.event_category,
      event_label: link,
    })

    props.router.push(link);
  }

  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
      className="mb-12 md:container"
    >
      {title && (
        <h3 className={`${viewport === "desktop" ? "" : "container"} mb-4 md:mb-6 text-base md:text-2xl font-bold font-poppins text-black`}>{title}</h3>
      )}
      <Swiper
        spaceBetween={viewport === "desktop" ? 28 : 16}
        centeredSlides={false}
        loop={true}
        navigation={viewport === "desktop" ? true : false}
        slidesPerView={viewport === "desktop" ? desktop_slides : mobile_slides}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="!pl-6 md:!pl-0"
      >
        {image_list.map((image, index) => (
          <SwiperSlide
            key={index}
            // className="min-w-[300px]"
          >
            <Link href={image.link} onClick={handleBannerClick(image.link)}>
              <Image
                src={image.image}
                alt="banner image"
                quality={100}
                width={1400}
                height={1596}
                className="rounded"
              />
            </Link>
          </SwiperSlide>
        ))}
        {image_list.map((image, index) => (
          <SwiperSlide
            key={index * 10}
            // className="min-w-[300px]"
          >
            <Link href={image.link} onClick={handleBannerClick(image.link)}>
              <Image
                src={image.image}
                alt="banner image"
                quality={100}
                width={1400}
                height={1596}
                className="rounded"
              />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </motion.div>
  )
}

export default CarouselBanner
